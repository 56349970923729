import React from "react";
import styles from "./popup.module.scss";
import { Props } from "./IPopup";

const Popup: React.FC<Props> = ({ children, setIsOpen }) => {
  return (
    <div className={styles.Overlay} onClick={setIsOpen}>
      <div className={styles.Popup}>{children}</div>
    </div>
  );
};

export default Popup;
