import React from "react";
import styles from "./eye-icon.module.scss";
import { Props } from "./IEyeIcon";

const EyeIcon: React.FC<Props> = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={styles.Eye}
      onClick={onClick}
    >
      <path
        d="M18.8574 11.9346L20.9959 15.6384"
        stroke="#B5B7BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4541 13.9932L15.121 17.7754"
        stroke="#B5B7BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.53714 13.9917L8.87012 17.7746"
        stroke="#B5B7BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.13799 11.9316L2.98926 15.6533"
        stroke="#B5B7BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9.83203C4.57617 11.783 7.4657 14.25 12.0001 14.25C16.5344 14.25 19.4239 11.783 21.0001 9.83205"
        stroke="#B5B7BA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeIcon;
